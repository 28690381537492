import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {AppState} from "../reducers";
import {
    selectDictionary,
    selectIsLoading,
    selectLoggedOut,
    selectNotification,
    selectUser
} from "../selectors/user.selectors";
import {UserActions} from "../actions";
import {NotificationModel} from "../../../models/interfaces/notification-model";

@Injectable({providedIn: "root"})
export class UserFacade {

    readonly user$ = this.store.select(selectUser);

    readonly setLoggedOut$ = this.store.select(selectLoggedOut);

    readonly dictionary$ = this.store.select(selectDictionary);

    readonly notifications$ = this.store.select(selectNotification);

    readonly isLoading$ = this.store.select(selectIsLoading);

    constructor(
        private readonly store: Store<AppState>
    ) {
    }

    setNotification(notification: NotificationModel): void {
        this.store.dispatch(UserActions.setNotification({notification}));
    }

    removeNotification(notification: NotificationModel): void {
        this.store.dispatch(UserActions.removeNotification({notification}));
    }

    setLoading(isLoading: boolean): void {
        this.store.dispatch(UserActions.setLoading({isLoading}));
    }

    setLoggedOut(): void {
        this.store.dispatch(UserActions.setLoggedOut());
    }
}
